import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  delete_basket_y,
} from "../../images";
import { httpDeleteWithToken, httpPostWithToken } from "../../utils/http_utils";
import { useNavigate } from "react-router-dom";

export default function DeleteAccountModal({
  isOpen,
  onClose,
  reason,
  onFinished,
}) {
  const finishSelection = () => {
    onClose();
    onFinished();
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const deleteAccount = async () => {
    if(isLoading) return;
    if(reason == "") {
      return toast({
        title: "Reason cannot be empty",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(true);
    var response = await httpPostWithToken("caregiver/deactivate", {
      reason : reason == "" ? "none" : reason
    });
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    } else {
      toast({
        title: "Account deleted successfully",
        // description: `${response.error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
      finishSelection();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p={4}>
        {/* <ModalHeader>Tasks</ModalHeader> */}
        {/* <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton> */}
        <ModalBody textAlign={"center"} marginBottom={"0"}>
          <Flex justifyContent={"center"}>
            <Image src={delete_basket_y} boxSize={"70px"} />
          </Flex>
          <ModalHeader textAlign={"center"}>Delete Account?</ModalHeader>
          <Text textAlign={"center"}>
            This action cannot be reversed!
          </Text>
          <Box w="100%" mt={"25px"}>
            <Button
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              Cancel
            </Button>

            <Button
              isLoading={isLoading}
              mt={8}
              mb={8}
              size={"lg"}
              color={"red"}
              w={"100%"}
              shadow={"sm"}
              border="1px solid #EBECEF"
              borderRadius={64}
              onClick={() => deleteAccount()}
            >
              Yes, Delete
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
