import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  basic,
  checkIcon,
  checkIconWhite,
  finance,
  noise,
  notask,
  provider,
  standard,
  starter,
  team,
  tribe,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import { AppContext } from "../../redux/appContext";
import SubscriptionSuccessful from "./subscription_successful";
import SubscriptionModal from "./subscriptionModal";

function AllPlans() {
  const { subscription, setSub } = useContext(AppContext);
  
  // const [tasks, setTasks] = useState([1,2,3,4,5,6])
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activePlan, setActivePlan] = React.useState("");
  const [ploading, setPLoading] = React.useState(false);
  const [planType, setPlanType] = React.useState("monthly");
  const [currrentSub, setCurrentSub] = React.useState({});
  const [currentPlan, setCurrentPlan] = React.useState({});
  
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user?.tag !== "cg";


  const navigate = useNavigate();
  const planImages = {
    starter: starter,
    standard: basic,
    premium: standard,
  };
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenSubscription,
    onOpen: onOpenSubscription,
    onClose: onCloseSubscription,
  } = useDisclosure();

  useEffect(() => {
    loadConstants();
    if(subscription){
      setActivePlan(subscription.plan_id)
    }
    
    if(user){
      if(user.tag != "cg"){
        navigate("/dashboard")
      }
    }
  
  }, []);

  useEffect(() => {
    var l = window.location.href;
    if (l.includes("trx")) {
      l = l.replace(window.location.host, "");
      l = l.split("trx=");
      l = l[1];
      verifyPayment(l.trim());
    } else {
      setPLoading(false);
    }
  }, []);

  const verifyPayment = async (payment) => {
    if (ploading) return false;
    setPLoading(true);
    var j = await httpGetWithTokenRest("subscription/status/" + payment);
    if (j.statusCode == 200) {
      // console.log(j.data)
      setPLoading(false);

      if (j.data.status == "paid") {
        setSub(j.data);
        setCurrentSub(j.data);
        onOpenCreate();
        return true;
      } else {
        return toast({
          title: "Sorry, we hit a snag1!",
          description: `${j.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${j.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const loadConstants = async () => {
    var j = await httpGetWithTokenRest("plans");
    if (j.status == "success") {
      console.log(j.data);
      setPlans(j.data);
    }

    setLoading(false);
  };
  const toast = useToast();
  const getPaymentLink = async (id, name, c = null) => {
    setLoading(true);
    let d = {
      plan_id: id,
      type: planType,
      callback_url: "http://" + window.location.host + "/validate-payment",
    };
    if (c) {
      d.coupon_code = c;
    }

    var j = await httpPostWithToken("subscription", d);
    if (j.status == "true" || j.status == true) {
      window.location.href = j.data.payload.url;
      return;
    }
    setLoading(false);

    return toast({
      title: "Sorry, we hit a snag!",
      description: `${j.error}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const subscribeCoupon = async (id, name, c = null) => {
    setLoading(true);
    let d = {
      plan: id,
      couponCode: c,
      type: planType,
    };
    var j = await httpPostWithToken("subscription-coupon", d);
    if (j.status == "success" || j.status == true) {
      setSub(j.data);
      setCurrentSub(j.data);
      onOpenCreate();
      return true;
    }
    setLoading(false);

    return toast({
      title: "Sorry, we hit a snag!",
      description: `${j.error}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
    

      <SubscriptionSuccessful
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        sub={currrentSub}
        onFinished={null}
      />
      <SubscriptionModal
        isOpen={isOpenSubscription}
        onClose={onCloseSubscription}
        plan={currentPlan}
        planType={planType}
        onFinished={(p, c, amount = 1) => {
          if(amount == 0) {
            subscribeCoupon(p.id, p.plan_name, c)
          }else{
            getPaymentLink(p.id, p.plan_name, c);
          }
        }}
      />

      <Spacer height={"22px"} />
      {ploading || loading ? (
            <Stack mt={5}>
              <Skeleton startColor="#808080" height="50px" />
              <Skeleton startColor="#808080" height="50px" />
              <Skeleton startColor="#808080" height="50px" />
            </Stack>
      ) : (
        <Box>
          <Flex flexDir="column" alignItems={"center"} justifyContent="center">
            <Heading
              as={"h3"}
              fontSize={"40px"}
              fontWeight="600"
              color="#101828"
            >
              Pricing plans
            </Heading>
            <Box h="5px" />
            <Text color="#475467">
              Sojorne offers simplare, transparent pricing that grows with you.
              Subscribe annually to save and lock our lowest prices.
            </Text>
          </Flex>
          <Box h="20px" />

          <Flex
            mx="auto"
            w={"fit-content"}
            borderRadius={"10px"}
            borderWidth={"2px"}
            borderColor={"#7353BA"}
            p="1"
            justifyContent={"center"}
            alignItems={"center"}
            gap="3"
          >
            <Box
              p="2"
              borderRadius={"10px"}
              d="flex"
              cursor={"pointer"}
              onClick={() => {
                setPlanType("monthly");
              }}
              justifyContent={"center"}
              alignItems={"center"}
              bg={planType == "monthly" ? "#7353BA" : "none"}
            >
              <Text
                fontSize="13px"
                fontWeight="600"
                color={planType == "monthly" ? "#fff" : "#667085"}
              >
                Monthly billing
              </Text>
            </Box>
            <Box
              p="2"
              borderRadius={"10px"}
              d="flex"
              cursor={"pointer"}
              onClick={() => {
                setPlanType("yearly");
              }}
              justifyContent={"center"}
              alignItems={"center"}
              bg={planType == "yearly" ? "#7353BA" : "none"}
            >
              <Text
                fontSize="13px"
                fontWeight="600"
                color={planType == "yearly" ? "#fff" : "#667085"}
              >
                Annual billing
              </Text>
            </Box>
          </Flex>
          <Spacer height={"22px"} />
          <SimpleGrid gap={3} columns={{ base: 1, md: 1, lg: 3 }}>
            {plans.map((plan, i) => (
              <Box
                display={"flex"}
                flexDirection="column"
                height={"540px"}
                borderColor={"#fff"}
                borderWidth={(i + 1) % 2 == 0 ? "4px" : "0"}
                rounded={"15px"}
                shadow={"sm"}
                px="4"
                py="4"
                key={i}
                bg={(i + 1) % 2 == 0 ? "primary" : "#EEE8FF"}
              >
                <Box h={"10px"} />
                <Box textAlign={"center"}>
                  <Image
                    mx="auto"
                    src={planImages[plan.plan_name.toLowerCase()]}
                    boxSize={"36px"}
                  />
                  <Box h={"10px"} />
                  <Heading
                    color={(i + 1) % 2 == 0 ? "white" : "#17181C"}
                    fontSize={"20px"}
                    fontWeight={"600"}
                  >
                    {plan.plan_name}
                  </Heading>
                  <Box h={"15px"} />
                  {plan.plan_name.toLowerCase() == "starter" ? (
                    <Heading color={"#7353BA"}>Free</Heading>
                  ) : (
                    <Heading
                      fontSize={"40px"}
                      color={(i + 1) % 2 == 0 ? "white" : "#7353BA"}
                    >
                      ${planType == "monthly" ? plan.monthly : plan.yearly}
                    </Heading>
                  )}
                </Box>
                {(i + 1) % 2 == 0 ? <Box h={"25px"} /> : <Box h={"45px"} />}
                {plan.features?.map((item, o) => (
                  <Flex
                    my={"2"}
                    color={(i + 1) % 2 == 0 ? "white" : "#17181C"}
                    key={o}
                    alignItems={"center"}
                    gap={"2"}
                  >
                    <Image
                      src={(i + 1) % 2 == 0 ? checkIconWhite : checkIcon}
                      boxSize={"20px"}
                    />
                    <Text fontWeight={"500"} fontSize={"14px"}>
                      {item.replace("Profileq", "Profile")}
                    </Text>
                  </Flex>
                ))}

                <Spacer />
                {plan.plan_name.toLowerCase() !== "starter" && (
                  <Button
                    onClick={() => {
                      setCurrentPlan(plan);
                      onOpenSubscription();
                    }}
                    isLoading={loading}
                    w={"100%"} 
                    bg={(i + 1) % 2 == 0 ? "#65CBE9" : "primary"}
                    color={"white"}
                  >
                    {activePlan == plan.id ? "Renew" : "Get started"}
                  </Button>
                )}
                {(i + 1) % 2 == 0 ? <Box h={"35px"} /> : <Box h={"10px"} />}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
      <Spacer height={"22px"} />
    </Box>
  );
}

export default AllPlans;
