import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout/dashboard";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Heading,
  Stack,
  Skeleton,
  Icon,
} from "@chakra-ui/react";
import { FaChevronLeft, FaEdit, FaEllipsisV } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";

import {
  httpDeleteWithToken,
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
  httpPostWithToken,
  timeAgo,
} from "../../utils/http_utils";
import moment from "moment";
import { MdLogout } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { BsSendFill } from "react-icons/bs";
import { Controller, useForm } from "react-hook-form";
import ls from "localstorage-slim";
import { communityRoute } from "../../utils/allRoutes";
import { MultiSelect } from "chakra-multiselect";
import { httpPatch2WithTokenRest } from "../../utils/http_utils";
import { auth, capitalizeFirstWord, database, firebaseConfig, isImage, isValidUrl } from "../../utils/utils";
import { ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";
import GroupEditedModal from "./group_edited_modal";
import { TaskItem } from "./community";
import CaregiverListModal from "./all_caregivers_modal";
import GroupRemoveModal from "./remove_member_modal";
import GroupMemberRemoved from "./group_member_removed";
import GroupMemberAdded from "./added_modal";
import WhoModal from "../tasks/who_modal";
import CarecircleInfoModal from "./carecircle_info_modal";
import InviteCareGiverModal from "../dashboard/co_caregiver_modal";
import { deleteIcon } from "../../images";
import { signInAnonymously } from "firebase/auth";
import { BiDotsVertical } from "react-icons/bi";
import DeleteMessageModal from "./delete_message_for_me";
import DeleteMessage4AllModal from "./delete_message_for_all";
import RenderFile from "./render_file";

export default function SingleCommunity() {
  const navigate = useNavigate();
  const params = useParams();
  const communityId = params.id;
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [community, setCommunity] = React.useState(null);
  const [communityMessages, setCommunityMessages] = React.useState([]);
  const [allCommunities, setAllCommunities] = React.useState([]);
  const [communityCreatedBy, setCreatedBy] = React.useState({});
  const [fileSelected, setFileSelected] = React.useState(null);
  const [isLoadingFile, setIsLoadingFile] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [activeMessage, setActiveMessage] = useState("");
  
  const [allInterest, setAllInterest] = React.useState([]);
  const [allInterestObj, setAllInterestObj] = React.useState([]);
  const [selectedInterest, setSelectedInterest] = React.useState([]);
  const [deleted4Me, setDeleted4Me] = React.useState([]);
  const [deleted4MeAll, setDeleted4MeAll] = React.useState([]);
  const [coCaregivers, setCoCaregivers] = useState([]);
  const [availableCCO, setAvailableCCO] = useState([]);
  const [inviteGiversConstants, setInviteGiversConstants] = useState([]);

  const isCoCaregiver = user.tag !== "cg" && user.caregiver_type != "primary caregiver";

  const [caregiverGroups, setCaregiverGroups] = React.useState([]);
  const [selectedCommunity, setSelectedCommunity] = React.useState(null);
  const [firebaseId, setFirebaseId] = React.useState("");
  
  const [selectedJorners, setSelectedJorner] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [toDeleteMessageTimestamp, setToDeleteMessageTimestamp] = React.useState(null);
  const [toDeleteMessageTimestampAll, setToDeleteMessageTimestampAll] = React.useState(null);
  const [toDeleteMessageCommunity, setToDeleteMessageCommunity] = React.useState(null);

  const [toDelete, setToDelete] = React.useState(null);
  const [activeGroup, setActiveGroup] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedCaregivers, setselectedCaregivers] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [jorners, setJorners] = React.useState([]);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [noMessageAtAll, setNoMessageAtAll] = React.useState(true);
  
  const [selectedCaregiversName, setselectedCaregiversName] = React.useState(
    []
  );


  const [selectedCreateInterest, setSelectedCreateInterest] = React.useState(
    []
  );
  const [myCommunities, setMyCommunities] = React.useState([]);
  const [communityMembers, setCommunityMembers] = React.useState([]);
  const toast = useToast();
  // const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const fileRef = React.useRef();
  const chooseFile = () => {
    fileRef.current.click();
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenEditCommunity,
    onOpen: onOpenEditCommunity,
    onClose: onCloseEditCommunity,
  } = useDisclosure();

  const {
    isOpen: isOpenModalWho,
    onOpen: onOpenModalWho,
    onClose: onCloseModalWho,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateCommunity,
    onOpen: onOpenCreateCommunity,
    onClose: onCloseCreateCommunity,
  } = useDisclosure();
  const {
    isOpen: isOpenMembers,
    onOpen: onOpenMembers,
    onClose: onCloseMembers,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteMessage,
    onOpen: onOpenDeleteMessage,
    onClose: onCloseDeleteMessage,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteMessageAll,
    onOpen: onOpenDeleteMessageAll,
    onClose: onCloseDeleteMessageAll,
  } = useDisclosure();

  const {
    isOpen: isOpenModalJorner,
    onOpen: onOpenModalJorner,
    onClose: onCloseModalJorner,
  } = useDisclosure();

  const scrollToBottom = () => {
    const chat = document.getElementById("chat");
    if (chat !== null) {
      chat.scrollTop = chat?.scrollHeight + 20;
    }
  };

  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setFileSelected(event.target.files[0]);
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      setIsLoadingFile(true);
      const resp = await httpPostWithToken(
        `communities/${communityId}/message`,
        formData
      );
      if (resp.error !== null && resp.error !== undefined) {
        setIsLoadingFile(false);
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${resp.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setFileSelected(null);
        setIsLoadingFile(false);
      }
    }
  };
  const getCocaregivers = async (open = "") => {
    var j = await httpGetWithTokenRest("co_caregiver");
    if (j.status == "success") {
      var user = JSON.parse(sessionStorage.getItem("sj_user"));
      let d = j.data;
      d = d.filter((c) => c.id !== user.id);
      // d = [...d, user];
      setCoCaregivers(d);
      setTimeout(() => {
        if(open == "member") viewMembers("member")
      }, 400);
    }
  };
  const getJorners = async () => {
    var url;
    if (user.tag == "cocg") {
      url = `co_caregiver/${user.id}/jorners`;
    } else {
      url = `jorner`;
    }
    var j = await httpGetWithTokenRest(url);
    if (j.status == "success") {
      const jor = j.data;
      const jornersss = jor.map((item, i) => {
        return {
          id: i,
          key: i,
          label: item.first_name + " " + item.last_name,
          value: item.id,
          ...item,
        };
      });
      setJorners(jornersss);
    }
  };
  const fetchCaregiverconstants = async () => {
    var j = await httpGetWithTokenRest("permissions");
    if (j.status == "success") {
      setInviteGiversConstants(j.data.permissions);
    }
  };
  useEffect(()=> {
    signInAnonymously(auth).catch((error) => {
      console.error("Error signing in anonymously: ", error);
    });
    if(firebaseId != "") {
      const dbRef = ref(database, 'communities/'+firebaseId);
      onValue(dbRef, (snapshot) => {
        const value = snapshot.val();
        if(value.messages){
          let remainingMessages = Object.values(value.messages);
          remainingMessages = remainingMessages.filter((f)=>deleted4Me.includes(f.timestamp.toString()) === false)
          remainingMessages = remainingMessages.filter((f)=>deleted4MeAll.includes(f.timestamp.toString()) === false)
          setCommunityMessages(remainingMessages);
        setNoMessageAtAll(false)
        }else{
        setCommunityMessages([])
        setNoMessageAtAll(true)
        }
        scrollToBottom();
      }, (error) => {
        // alert("Error reading data1: " + error.message);
        setCommunityMessages([])
      });
    }

  }, [firebaseId, deleted4Me, deleted4MeAll, noMessageAtAll])
  // };
  const fetchCarecircleGroups = async (open = "") => {
    const resp = await httpGetWithTokenRest("care_circle/me");
    if (resp.error !== null && resp.error !== undefined) {
      setLoading(false);

    } else {
      const d = resp.data;
      var c = d.find((c) => c.id === communityId);
      if(c) {
        setCreatedBy(c.created_by)
        setCommunity(c)  
        console.log(c.communities_firebase_id)
        setFirebaseId(c.communities_firebase_id)
      }
      if(window.location.href.includes("type=careteam")){
        fetchFamilyCommunity()
      }
    var pCommunity = d.filter((c) => c.community_type === "private");
    setCaregiverGroups(pCommunity);
    setMyCommunities(pCommunity);
    setLoading(false);
    if(open == "member") getCocaregivers("member")
    }
  };

  const viewMembers = (open = "") => {
    let c = [];
    let mem = [];
    mem = community?.members;

    // alert(mem.length)
    // alert(coCaregivers.length)
    for (
      let i = 0;
      i < coCaregivers.length;
      i++
    ) {
      const co = coCaregivers[i];
      let isExist = members.find(
        (l) => l.id == co.id
      );
      if (!isExist) {
        c = [...c, co];
      } else {
      
      }
    }
    let isExist = null;
    for (let i = 0; i < mem.length; i++) {
      const m = mem[i];
      isExist = coCaregivers.find(
        (l) => l.id == m.id
      );
      if (isExist) {
        isExist.cm_tag = isExist.caregiver_type;
        mem[i] = isExist;
      }
    }
    setAvailableCCO(c);
    setMembers(mem);
    if(open == "member") {
      onOpenAdded()
    }else{
      onOpenMembers();
      setActiveGroup(community);
    }
  }
  // const fetchMyCommunities = async () => {
  //   const resp = await httpGetWithTokenRest("communities/me");
  //   if (resp.error !== null && resp.error !== undefined) {
  //     return toast({
  //       title: "Sorry, we hit a snag!",
  //       description: `${resp.error}`,
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } else {
  //     setMyCommunities(resp.data);
  //   }
  // };

  const fetchCommunityMessages = async () => {    
    // const resp = await httpGetWithTokenRest(
    //   `communities/${communityId}/message`
    // );
    // if (resp.error !== null && resp.error !== undefined) {
    //   // return toast({
    //   //   title: "Sorry, we hit a snag!",
    //   //   description: `${resp.error}`,
    //   //   status: "error",
    //   //   duration: 5000,
    //   //   isClosable: true,
    //   // });
    // } else {
      
    // }
  };

  const fetchAllCommunities = async () => {
    const resp = await httpGetWithTokenRest("communities");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setAllCommunities(resp.data.rows);
    }
  };
  const fetchFamilyCommunity = async () => {
    const resp = await httpGetWithTokenRest("communities/family");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setCommunity(resp.data);
    }
  };
  const fetchAllInterests = async () => {
    // const resp = await httpGetWithTokenRest("group-interests");
    // if (resp.error !== null && resp.error !== undefined) {
    //   return toast({
    //     title: "Sorry, we hit a snag!",
    //     description: `${resp.error}`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // } else {
    //   setAllInterest(resp.data);
    //   const allInt = [];
    //   for (let i = 0; i < resp.data.length; i++) {
    //     const element = resp.data[i];
    //     let data = {
    //       id: element,
    //       value: element,
    //     };
    //     allInt.push(data);
    //   }
    //   setAllInterestObj(allInt);
    // }
  };
  const {
    isOpen: isOpenCoGiverModal,
    onOpen: onOpenCoGiverModal,
    onClose: onCloseCoGiverModal,
  } = useDisclosure();
  const {
    isOpen: isOpenInfo,
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
  } = useDisclosure();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const {
    isOpen: isOpenAdded,
    onOpen: onOpenAdded,
    onClose: onCloseAdded,
  } = useDisclosure();
  const {
    isOpen: isOpenEdited,
    onOpen: onOpenEdited,
    onClose: onCloseEdited,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleted,
    onOpen: onOpenDeleted,
    onClose: onCloseDeleted,
  } = useDisclosure();
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    reset: reset2,
    setValue: setValue2,
    formState: { errors: error2, isSubmitting: isSubmitting2 },
  } = useForm();

  const {
    handleSubmit: handleEditCommunity,
    register: registerEdit,
    control: controlEdit,
    reset: resetEdit,
    formState: { errors: errorEdit, isSubmitting: isSubmittingEdit },
  } = useForm();
 
  async function onSubmit(values) {
    const formData = new FormData();
    if (values !== undefined) {
      formData.append("message", values.message);
    }

    if (fileSelected !== null) {
      formData.append("file", fileSelected);
    }
    const resp = await httpPostWithToken(
      `communities/${communityId}/message`,
      formData
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      reset();
      if(noMessageAtAll) setNoMessageAtAll(false);
      setFileSelected(null);
    }
  }
  const addMembers = async (v) => {
    setLoading(true);
    let resp = await httpPatch2WithTokenRest("/care_circle/members/add", {
      co_caregiver_id: v,
      community_id: activeGroup ? activeGroup.id : "",
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchCarecircleGroups()
      setActiveGroup(null);
      setMembers([]);
      onOpenAdded();
    }
    setLoading(false);
  };
  async function onSubmit2(values) {
    // if (!selectedJorners) {
    //   return toast({
    //     title: "All fields are required!",
    //     description: `Please select a Jorner`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
    // if (selectedCaregivers.length == 0) {
    //   return toast({
    //     title: "All fields are required!",
    //     description: `Please select Caregivers`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
    // if (selectedCreateInterest.length < 1) {
    //   return toast({
    //     title: "Sorry, we hit a snag!",
    //     description: `Please select an interest`,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
    values.interest = ["group"];
    values.size = 1;
    // values.created_by_tag = "caregiver";
    // values.jorner = selectedJorners.id;
    // values.co_caregivers = selectedCaregivers;
    let d = {
      name: values.name,
      descriptions: values.descriptions,
    }
    let resp;
    resp = await httpPatch2WithTokenRest(
      "care_circle/" + activeGroup?.id,
      d
    );

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchCarecircleGroups()
      let comm = community;
      comm.name = d.name;
      comm.descriptions = d.descriptions;
      setCommunity(comm)
      setTimeout(() => {
        onCloseCreateCommunity();
        onOpenEdited()
      }, 400);
      // return toast({
      //   title: "Care Circle updated",
      //   status: "success",
      //   duration: 5000,
      //   isClosable: true,
      // });
      //  fetchConstants()
    }
  }

  async function onEditCommunity(values) {
    values.interest = [...selectedCreateInterest];
    const resp = await httpPatch2WithTokenRest(
      `communities/${communityId}`,
      values
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      onCloseEditCommunity();
      return toast({
        title: "Success.",
        description: `Community details edited successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const leaveCommunity = async () => {
    const resp = await httpDeleteWithToken(`communities/${communityId}/leave`);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate(communityRoute);
    }
  };
  const fetchDeletedMessagesForMe = async () => {
    const resp = await httpGetWithTokenRest(`deleted-message/me/${communityId}`);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      let d = resp.data;

      let msgs = d.map((msg)=> {return msg.del_timestamp})
      // console.log("delted", msgs)
      setDeleted4Me(msgs)
    }
  };
  const fetchDeletedMessagesForMeAll = async () => {
    const resp = await httpGetWithTokenRest(`deleted-message/${communityId}`);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      let d = resp.data;

      let msgs = d.map((msg)=> {return msg.del_timestamp})
      // console.log("delted", msgs)
      setDeleted4MeAll(msgs)
    }
  };

  React.useEffect(() => {
    getJorners()
    fetchConstants();
  }, [communityId]);
  function fetchConstants() {
    fetchCarecircleGroups();
    getCocaregivers()
    fetchCaregiverconstants()
    fetchDeletedMessagesForMe();
    fetchDeletedMessagesForMeAll();
  }
  return (
    <DashboardLayout
      element={
        <Box p={{ base: 2, md: 5, lg: 5 }} bg={"#FAF8FF"}>
          {loading && (
            <Stack mt={5}>
              <Skeleton startColor="#808080" height="50px" />
              <Skeleton startColor="#808080" height="50px" />
              <Skeleton startColor="#808080" height="50px" />
            </Stack>
          )}
          <InviteCareGiverModal
            coGiverConstants={inviteGiversConstants}
            onClose={onCloseCoGiverModal}
            isOpen={isOpenCoGiverModal}
            jorners={jorners}
            community={activeGroup ? activeGroup.id : ""}
            callback={() => {
              fetchCarecircleGroups("member")
              onCloseModalWho()
              onCloseMembers()
            }}
          />
           <DeleteMessageModal
            onClose={onCloseDeleteMessage}
            isOpen={isOpenDeleteMessage}
            community={toDeleteMessageCommunity ? toDeleteMessageCommunity : ""}
            timestamp={toDeleteMessageTimestamp ? toDeleteMessageTimestamp : ""}
            onFinished={()=> {
              fetchDeletedMessagesForMe()
            }}
          />
          <DeleteMessage4AllModal
            onClose={onCloseDeleteMessageAll}
            isOpen={isOpenDeleteMessageAll}
            community={toDeleteMessageCommunity ? toDeleteMessageCommunity : ""}
            timestamp={toDeleteMessageTimestampAll ? toDeleteMessageTimestampAll : ""}
            onFinished={()=> {
              fetchDeletedMessagesForMeAll()
            }}
          />

          <CarecircleInfoModal
            isOpen={isOpenInfo}
            onClose={onCloseInfo}
            onFinished={(v) => {
              onCloseInfo();
              if (v == "view") {
                let community = activeGroup;
                let c = [];
                let mem = [];

                mem = community.members;
                for (let i = 0; i < coCaregivers.length; i++) {
                  const co = coCaregivers[i];
                  let isExist = community.members.find((l) => l.id == co.id);
                  if (!isExist) {
                    c = [...c, co];
                  } else {
                  }
                }
                let isExist = null;
                for (let i = 0; i < mem.length; i++) {
                  const m = mem[i];
                  isExist = coCaregivers.find((l) => l.id == m.id);
                  if (isExist) {
                    isExist.cm_tag = isExist.caregiver_type;
                    mem[i] = isExist;
                  }
                }
                setAvailableCCO(c);
                setMembers(mem);
                onOpenMembers();
                setActiveGroup(community);
              }

              if (v == "chat") {
                navigate(`/care-circle/${activeGroup.id}`);
              }

              if (v == "invite") {
                onOpenCoGiverModal();
              }
              setSelectedJorner(null);
            }}
            title={activeGroup ? activeGroup.name : ""}
            constants={activeGroup ? members : []}
            jorner={selectedJorners}
          />

          <WhoModal
            title={"Co-Caregiver"}
            buttonTitle={"Proceed"}
            invite={true}
            isOpen={isOpenModalWho}
            onFinished={(v) => {
              if (v == "close") {
                if (members.length > 0) {
                  onOpenMembers();
                } else {
                  onOpenCreateCommunity();
                }
                onCloseModalWho();
              } else if (v == "invite") {
                onCloseModalWho();

                onOpenCoGiverModal();
              } else {
                let selected = [];
                for (let i = 0; i < v.length; i++) {
                  const s = v[i];
                  let cocg = coCaregivers.find((c) => c.id == s);
                  if (cocg) {
                    selected = [
                      ...selected,
                      `${cocg.first_name} ${cocg.last_name}`,
                    ];
                  }
                }
                if (members.length > 0) {
                  if (v && v.length > 0) addMembers(v);
                } else {
                  setselectedCaregivers(v);
                  setselectedCaregiversName(selected);
                  onOpenCreateCommunity();
                  onCloseModalWho();

                }
              }
            }}
            source="community"
            constants={coCaregivers}
            selectedOriginal={selectedCaregivers}
            onClose={onCloseModalWho}
            activeList={members}
          />
          <GroupMemberAdded
            isOpen={isOpenAdded}
            onClose={onCloseAdded}
            onFinished={() => {
              onCloseAdded();
              onCloseCreateCommunity();
            }}
          />
          <GroupMemberRemoved
            isOpen={isOpenDeleted}
            onClose={onCloseDeleted}
            onFinished={() => {
              onCloseDeleted();
            }}
          />

          <GroupRemoveModal
            isOpen={isOpenDelete}
            onClose={onCloseDelete}
            isDeleting={isDeleting}
            onFinished={(v) => {
              onCloseDelete();
              if (v == "close") {
                onOpenMembers();
              } else {
                onCloseDelete();
                onOpenDeleted();
                fetchCarecircleGroups()
                setMembers([]);
              }
            }}
            cm={activeGroup ? activeGroup.id : ""}
            member={toDelete}
          />


          <GroupEditedModal
            isOpen={isOpenEdited}
            onClose={onCloseEdited}
            onFinished={() => {
              onCloseEdited();
              onCloseCreateCommunity();
            }}
          />
          <CaregiverListModal
            isOpen={isOpenMembers}
            onClose={onCloseMembers}
            onFinished={(v, y) => {
              if (v == "add") {
                onOpenModalWho();
                onCloseMembers();
              } else if (v == "close") {
                setMembers([]);
                onCloseMembers();
              } else if (v == "delete") {
                setToDelete(y);
                onCloseMembers();
                onOpenDelete();
              }
            }}
            title={activeGroup ? activeGroup.name : ""}
            constants={activeGroup ? members : []}
          />

          {/* <CommunityMembersModal
            isOpen={isOpenMembers}
            onFinished={(v) => {
              // loginToFamily(v);
              if (v) {
                setSelectedMember(v.user_id);
                onOpenDelete();
              }
            }}
            constants={community ? communityMembers : []}
            createdBy={communityCreatedBy?.user_id}
            onClose={onCloseMembers}
          /> */}
          <Flex
            flexDirection={{ base: "column", md: "row", lg: "row" }}
            gap={3}
          >
            <Box
              display={{ base: "none", md: "block", lg: "block" }}
              p={2}
              bg={"#fff"}
              borderRadius={12}
            >
              <Flex
                gap={5}
                alignItems={"center"}
                onClick={() => navigate(communityRoute)}
                cursor={"pointer"}
              >
                <FaChevronLeft cursor={"pointer"} />
                <Text fontSize={20} fontWeight={600}>
                  All Care Circle
                </Text>
              </Flex>
              <Box mt={3} overflowY={"scroll"} h={"80vh"} w={"300px"}>
                {myCommunities.map((e, i) => (
                  <>
                    {e.community_type == "private" && (
                      <Box
                        cursor={"pointer"}
                        bg={"#fff"}
                        border={"1px solid #EBECEF"}
                        borderRadius={12}
                        p={3}
                        onClick={() => navigate(`${communityRoute}/${e?.id}`)}
                      >
                        <Text
                          textOverflow={"ellipsis"}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {e?.name}
                        </Text>
                        
                        <Flex
                          alignItems={"center"}
                          display={{ base: "block", md: "flex" }}
                        >
                          <AvatarGroup size="sm" max={3}>
                            {e?.members.map((e, i) => (
                              <Avatar name={e?.first_name} src={e?.avatar} />
                            ))}
                          </AvatarGroup>
                          <Spacer />
                          <Button
                            mt={2}
                            mb={4}
                            size={"sm"}
                            minW={"80px"}
                            color="#fff"
                            borderRadius={64}
                            background={"#E7FEEE"}
                            border={"1px solid #0CCA4A"}
                          >
                            <Text
                              fontSize={12}
                              fontWeight={500}
                              color={"#099A38"}
                            >
                              {timeAgo(new Date(e?.created_at))} ago
                              {/* {moment(e?.created_at.replace("T", " ").replace("Z", " ")).fromNow()} */}
                            </Text>
                          </Button>
                        </Flex>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            </Box>
            <Box
              p={2}
              bg={"#fff"}
              borderRadius={12}
              w={{ base: "100%", md: "70%" }}
            >
              <Flex alignItems={"center"} py={2}>
                <FaChevronLeft
                  display={{ base: "block", md: "none", lg: "none" }}
                  onClick={() => navigate(communityRoute)}
                  cursor={"pointer"}
                />

                <Text fontSize={20} fontWeight={600}>
                  {community?.name}
                </Text>
                <Spacer />
                {
                  window.location.href.includes("type=careteam") 
                  ?
                  <Menu>
                     <MenuButton
                    as={Button}
                    rightIcon={<FaEllipsisV color={"#747A8B"} />}
                  >
                    {" "}
                  </MenuButton>
                  <MenuList border={"none"} boxShadow={"md"}>
                     <MenuItem>
                      <Flex
                        alignItems={"center"}
                        color={"#747A8B"}
                        onClick={() => {
                          viewMembers()
                        }}
                      >
                        <Image
                          src="/view_members.png"
                          boxSize={"18px"}
                        />
                        <Text ml={1}>View Members</Text>
                      </Flex>
                    </MenuItem>
                    </MenuList>
                  </Menu>
                  :
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<FaEllipsisV color={"#747A8B"} />}
                  >
                    {" "}
                  </MenuButton>
                  <MenuList border={"none"} boxShadow={"md"}>
                    {communityCreatedBy.user_id == user.user_id && (
                      <MenuItem>
                        <Flex
                          alignItems={"center"}
                          color={"#747A8B"}
                          onClick={() => {
                            setActiveGroup(community);
                            setIsEdit(true);
                            let j = community.jorner;
                            j.id = j.jorner_id;
                            setSelectedJorner(j);
                            let c = community.members;
                            let selected = [];
                            let v = [];
                            for (let i = 0; i < c.length; i++) {
                              const s = c[i];
                              let cocg = coCaregivers.find(
                                (co) => co.id == s.id
                              );
                              if (cocg) {
                                selected = [
                                  ...selected,
                                  `${cocg.first_name} ${cocg.last_name}`,
                                ];
                                v = [...v, cocg.id];
                              }
                            }
                            setselectedCaregivers(v);
                            setselectedCaregiversName(selected);
                            setValue2("name", community.name);
                            onOpenCreateCommunity();
                            setValue2(
                              "descriptions",
                              community.descriptions
                            );
                          }}
                        >
                          {/* <FaEdit color="green" /> */}
                          <Image src="/edit_group.png" boxSize={"22px"} />
                          <Text ml={1} >
                            Edit group
                          </Text>
                        </Flex>
                      </MenuItem>
                    )}

                    <MenuItem>
                      <Flex
                        alignItems={"center"}
                        color={"#747A8B"}
                        onClick={() => {
                          viewMembers()
                        }}
                      >
                        <Image
                          src="/view_members.png"
                          boxSize={"18px"}
                        />
                        <Text ml={1}>View Members</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem>
                      <Flex
                        alignItems={"center"}
                        color={"#747A8B"}
                        onClick={() => {
                          setMembers(community.members);
                          setSelectedJorner(community.jorner);
                          setActiveGroup(community);
                          onOpenInfo();
                        }}
                      >
                        {/* git status */}
                        <Image src="/info.png" boxSize={"22px"} />
                        <Text ml={1} >
                          View Circle Info
                        </Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem>
                      {isCoCaregiver ? (
                        <Flex
                          alignItems={"center"}
                          color={"#747A8B"}
                          onClick={() => {
                            onOpenDelete();
                            setIsDeleting(false)
                            setActiveGroup(community);
                            setToDelete(user.user_id);
                          }}
                        >
                          {/* git status */}
                          <Image src={deleteIcon} boxSize={"22px"} />
                          <Text ml={1}>
                            Leave Group
                          </Text>
                        </Flex>
                      ) : (
                        <Flex
                          alignItems={"center"}
                          color={"#747A8B"}
                          onClick={() => {
                            if (community.members.length > 1) {
                              return toast({
                                title: "Unable to delete group",
                                description:
                                  "Please remove all members first",
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                              });
                            } else {
                              setIsDeleting(true)
                              onOpenDelete();
                              setActiveGroup(community);
                              setToDelete(user.user_id);
                            }
                          }}
                        >
                          {/* git status */}
                          <Image src={deleteIcon} boxSize={"22px"} />
                          <Text ml={1}>
                            Delete Group
                          </Text>
                        </Flex>
                      )}
                    </MenuItem>

                  </MenuList>
                </Menu>
                }
              </Flex>
              <Divider color={"#747A8B"} />
              <Box h={"70vh"} mb={2} overflowY={"scroll"} id="chat">
                {communityMessages.map((message, i) => {
                  if (message?.sender?.id === user?.user_id) {
                    return (
                      <>
                      {
                        deleted4Me.includes(message.timestamp) ?
                        <></>
                        :
                      <Box
                      onMouseEnter={()=> {
                        setActiveMessage(message.timestamp)
                      }}
                      onMouseLeave={()=> {
                          setActiveMessage("")
                      }}
                       display={"flex"} mt={2} mr={3}
                      style={{
                        position : "relative"
                      }}
                      >
                        <Spacer />
                        <Box bg={"#FFE5E6"} p={3} borde rRadius={8}>
                          { message?.fileURL ? (
                           <RenderFile message={message} />
                          ) : (
                            <Text>{message?.text}</Text>
                          )}
                          <Text
                            mt={2}
                            textAlign={"right"}
                            fontSize={12}
                            fontWeight={500}
                            color={"#000"}
                          >
                            {moment(message?.timestamp).format("LT")}
                          </Text>
                        </Box>
                        {

                          activeMessage == message.timestamp 
                          &&
                        <Menu 
                        >
                          <MenuButton
                            background="white"
                            border={"0px"}
                            color="black"
                            fontSize={"20px"}
                            borderRadius="60px"
                            fontWeight={600}
                            position={"absolute"}
                            zIndex={"121121"}
                            right="5px"
                            top="5px"
                            shadow={"lg"}
                          >
                            <Icon as={BiDotsVertical} fontSize={"20px"} color={"primary"} />
                          </MenuButton>
                          <MenuList border={0} shadow={"lg"} borderRadius={"8px"}>
                                <MenuItem
                                  onClick={() => {
                                    setToDeleteMessageTimestamp(message.timestamp)
                                    setToDeleteMessageCommunity(community?.id)
                                    onOpenDeleteMessage()
                                  }}
                                >
                                  <Text 
                                  fontSize={"13px"}
                                  color={"#333"}
                                  >Delete for me</Text>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setToDeleteMessageTimestampAll(message.timestamp)
                                    setToDeleteMessageCommunity(community?.id)
                                    onOpenDeleteMessageAll()
                                  }}
                                >
                                  <Text 
                                  fontSize={"13px"}
                                  color={"#333"}
                                  >Delete for everyone</Text>
                                </MenuItem>
                            </MenuList>
                          </Menu>
                        }

                      </Box>
                      }
                      </>
                    );
                  } else {
                    return (
                      <>
                       {
                        deleted4Me.includes(message.timestamp) ?
                        <></>
                        :
                      <Box display={"flex"} mt={2} mr={3}
                      onMouseEnter={()=> {
                        setActiveMessage(message.timestamp)
                      }}
                      onMouseLeave={()=> {
                          setActiveMessage("")
                      }}
                     
                      
                      >
                        <Box bg={"primary"} p={3} borderRadius={8}
                        style={{
                          position : "relative"
                        }}
                        >
                          {

                            activeMessage == message.timestamp 
                            &&
                            <Menu 
                            >
                            <MenuButton
                              background="white"
                              border={"0px"}
                              paddingBottom={0}
                              color="black"
                              fontSize={"20px"}
                              borderRadius="60px"
                              fontWeight={600}
                              position={"absolute"}
                              zIndex={"121121"}
                              right="5px"
                              top="5px"
                              shadow={"lg"}
                            >
                              <Icon as={BiDotsVertical} fontSize={"20px"} color={"primary"} />
                            </MenuButton>
                            <MenuList border={0} shadow={"lg"} borderRadius={"8px"}>
                                <MenuItem
                                  onClick={() => {
                                    setToDeleteMessageTimestamp(message.timestamp)
                                    setToDeleteMessageCommunity(community?.id)
                                    onOpenDeleteMessage()
                                  }}
                                >
                                  <Text 
                                  fontSize={"13px"}
                                  color={"#333"}
                                  >Delete for me</Text>
                                </MenuItem>
                            </MenuList>
                            </Menu>
                          }
                        

                          <Box display={"flex"} gap={2}>
                            <Avatar src={message?.sender?.avatar} size={"sm"} />
                            <Box>
                              {message?.fileURL ? (
                                  <RenderFile message={message} />
                              ) : (
                                <Text color={"#fff"}>{message?.text}</Text>
                              )}

                              <Flex mt={2}>
                                <Text
                                  fontSize={12}
                                  fontWeight={500}
                                  color={"rgba(255, 255, 255, 0.64)"}
                                >
                                  {message?.sender?.name}{" "}
                                </Text>
                                <Box w={"8px"} />
                                <Text
                                  fontSize={12}
                                  fontWeight={500}
                                  color={"#fff"}
                                >
                                  {moment(message?.timestamp).format("LT")}
                                </Text>
                              </Flex>
                            </Box>
                          </Box>
                        </Box>
                        <Spacer />
                      </Box>
                    }
                    </>
                    );
                  }
                })}
                {
                  communityMessages.length == 0 && 
                  <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Text fontWeight={"bold"} color={"primary"}>Start a conversation...</Text>
                  </Box>
                }
              </Box>
              <Box w="100%">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex px={"1"}>
                    <Input
                      ref={fileRef}
                      type={"file"}
                      onChange={(e) => handleFileSelect(e)}
                      hidden
                    />
                    <IconButton
                      borderRadius={100}
                      bg={"#F7F7F8"}
                      p={3}
                      onClick={chooseFile}
                      icon={isLoadingFile ? <Spinner /> : <GrAttachment />}
                    />
                    <FormControl
                      isInvalid={errors.message}
                      fontWeight={600}
                      mr={2}
                    >
                      <Input
                        fontSize={12}
                        fontWeight={400}
                        id="message"
                        minW={"200px"}
                        borderRadius={8}
                        borderColor={"#C7CAD1"}
                        bg={"#F7F7F8"}
                        placeholder="e.g. Type your message..."
                        {...register("message", {
                          required: "This is required",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.message && errors.message.message}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      size={"sm"}
                      w={"40px"}
                      h={"40px"}
                      color="#fff"
                      borderRadius={100}
                      background={"primary"}
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      <Flex alignContent={"center"}>
                        <BsSendFill color="#fff" />
                      </Flex>
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Box>
          </Flex>
          <Modal
            isOpen={isOpenEditCommunity}
            onClose={onCloseEditCommunity}
            size={"2xl"}
            isCentered
            closeOnOverlayClick={false}
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader>New Community</ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <form onSubmit={handleEditCommunity(onEditCommunity)}>
                  <FormControl isInvalid={errors.name} mt={3}>
                    <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                      Name
                    </FormLabel>
                    <Input
                      id="name"
                      name="name"
                      placeholder="e.g Simon"
                      {...registerEdit("name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.description} mt={3}>
                    <FormLabel
                      htmlFor="description"
                      fontWeight={600}
                      fontSize={14}
                    >
                      Description
                    </FormLabel>
                    <Textarea
                      id="descriptions"
                      name="descriptions"
                      placeholder="e.g Simon"
                      {...registerEdit("descriptions", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={3}>
                    <FormLabel
                      htmlFor="interest"
                      fontWeight={600}
                      fontSize={14}
                    >
                      Group interest Category
                    </FormLabel>
                    <Box color={"#000"}>
                      <MultiSelect
                        name="interest"
                        color={"#000"}
                        bg={"#000"}
                        textColor={"#000"}
                        selectedListProps={{ color: "#000" }}
                        labelProps={{ color: "#000" }}
                        options={allInterestObj}
                        value={selectedCreateInterest}
                        // label='Choose an item'
                        onChange={setSelectedCreateInterest}
                      />
                    </Box>
                  </FormControl>

                  <FormControl mt={5} isInvalid={errors.size} fontWeight={600}>
                    <FormLabel htmlFor="size">Group Size Limit</FormLabel>
                    <Controller
                      name="communities_size"
                      id="size"
                      control={controlEdit}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value}
                          aria-required
                        >
                          <SimpleGrid columns={{ base: 2, md: 2 }} gap={2}>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="49"
                              >
                                1 - 49 Members
                              </Radio>
                            </Box>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="99"
                              >
                                50 - 99 Members
                              </Radio>
                            </Box>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="149"
                              >
                                100 - 149 Members
                              </Radio>
                            </Box>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="500"
                              >
                                150 - 500 Members
                              </Radio>
                            </Box>
                          </SimpleGrid>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.size && errors.size.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Edit Community
                  </Button>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>




          {/* create community modal */}
          <Modal
            isOpen={isOpenCreateCommunity}
            onClose={() => {
              onCloseCreateCommunity();
              setIsEdit(false);
              setMembers([]);
              setSelectedGroup(null);
              setActiveGroup(null);
              setValue2("name", "");
              setValue2("descriptions", "");
              setSelectedJorner(null);
              setselectedCaregivers([]);
              setselectedCaregiversName([]);
            }}
            size={"2xl"}
            isCentered
            closeOnOverlayClick={false}
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader>
                {isEdit
                  ? capitalizeFirstWord(
                    "Edit " + activeGroup?.name + " Care Circle Group"
                  )
                  : "Care Circle Group"}
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                  {!isEdit && (
                    <FormControl mt={3}>
                      <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                        Jorner
                      </FormLabel>
                      <InputGroup>
                        <Input
                          cursor={"pointer"}
                          readOnly={true}
                          _disabled={{
                            border: "2px solid #E3E4E8",
                            opacity: selectedJorners ? 1 : 0.7,
                          }}
                          onClick={() => {
                            onOpenModalJorner();
                            onCloseCreateCommunity();
                          }}
                          focusBorderColor="#E3E4E8"
                          border={"1px solid #E3E4E8"}
                          height="50px"
                          name="strenths"
                          value={
                            selectedJorners
                              ? `${selectedJorners.first_name} ${selectedJorners.last_name}`
                              : ""
                          }
                          placeholder={
                            selectedJorners
                              ? `${selectedJorners.first_name} ${selectedJorners.last_name}`
                              : "Select"
                          }
                        />
                        <InputRightElement>
                          <ChevronDownIcon
                            mt="2"
                            fontSize={"22"}
                            color="gray.500"
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  )}
                  <FormControl isInvalid={error2.name} mt={3}>
                    <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                      Group Name
                    </FormLabel>
                    <Input
                      id="name"
                      name="name"
                      placeholder="e.g Jamie’s Care Circle Group"
                      {...register2("name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {error2.name && error2.name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={error2.descriptions} mt={3}>
                    <FormLabel
                      htmlFor="descriptions"
                      fontWeight={600}
                      fontSize={14}
                    >
                      Description
                    </FormLabel>
                    <Textarea
                      id="descriptions"
                      name="descriptions"
                      placeholder="e.g Simon"
                      {...register2("descriptions", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {error2.descriptions && error2.descriptions.message}
                    </FormErrorMessage>
                  </FormControl>
                  {!isEdit && (
                    <FormControl mt={3}>
                      <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                        Co-Caregiver
                      </FormLabel>
                      <InputGroup>
                        <Input
                          cursor={"pointer"}
                          readOnly={true}
                          _disabled={{
                            border: "2px solid #E3E4E8",
                            opacity:
                              selectedCaregiversName.length < 0 ? 1 : 0.7,
                          }}
                          onClick={() => {
                            onCloseCreateCommunity();
                            onOpenModalWho();
                          }}
                          focusBorderColor="#E3E4E8"
                          border={"1px solid #E3E4E8"}
                          height="50px"
                          name="ccg"
                          value={selectedCaregiversName.join(", ")}
                          placeholder={
                            selectedCaregiversName.length < 0
                              ? selectedCaregiversName.join(", ")
                              : "Select"
                          }
                        />
                        <InputRightElement>
                          <ChevronDownIcon
                            mt="2"
                            fontSize={"22"}
                            color="gray.500"
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  )}

                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    type="submit"
                    isLoading={isSubmitting2}
                  >
                    {isEdit ? "Update Changes" : "Create Group"}
                  </Button>
                  <Text
                    textAlign={"center"}
                    color={"primary"}
                    textDecoration={"underline"}
                    fontSize={14}
                    fontWeight={600}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      onCloseCreateCommunity();
                    }}
                  >
                    Understand How Sojorne Care Circle Group Works
                  </Text>
                  <Box height={"20px"} />
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>


          {/* Task modal   */}
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"xl"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Heading textAlign={"center"} color={"#FF6E57"} fontSize={20}>
                  How it works
                </Heading>
                <TaskItem
                  img={"/cm1.png"}
                  title={"Navigate to the “Care Circle”"}
                  subtitle={
                    "Find the “Quick Action Section” on the dashboard and click Care Circle. Create a team if you’re new."
                  }
                />
                <TaskItem
                  img={"/cm2.png"}
                  title={"Invite Co-caregivers"}
                  subtitle={
                    "Tap 'Invite Co-Caregiver to begin assembling your support network. Invite family members, friends, or trusted caregivers by entering their email addresses and phone numbers."
                  }
                />
                <TaskItem
                  img={"/cm3.png"}
                  title={"Assign roles or permissions"}
                  subtitle={
                    "Assign roles or permissions to each member based on their involvement or responsibilities. Ensure that 'Community' is checked when inviting co-caregivers to the care circle."
                  }
                />
                <TaskItem
                  img={"/cm4.png"}
                  title={"Collaborate & Share"}
                  subtitle={
                    "Share updates, tasks, and schedules within the circle, collaborate efficiently by assigning tasks, sharing important information, and keeping everyone in the loop."
                  }
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      }
    />
  );
}
